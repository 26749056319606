import 'styles/global.scss';

import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import { REDIRECTED_HOSTNAME } from 'config/constants';
import { useAuth, useLanguage } from 'hooks';
import { AuthRoute, HvAlert } from 'components';
import { Response } from 'pages/Feedback/components';
import { ThankYou } from 'pages/SubscriptionPlans/components';

const Login = lazy(() => {
  return import('pages/Login');
});

const FirstTimeLogin = lazy(() => {
  return import('pages/FirstTimeLogin');
});

const Register = lazy(() => {
  return import('pages/SubscriptionPlans');
});

const ResetPassword = lazy(() => {
  return import('pages/ResetPassword');
});

const RequestResetPassword = lazy(() => {
  return import('pages/RequestResetPassword');
});

const Profile = lazy(() => {
  return import('pages/Profile');
});

const MediaManager = lazy(() => {
  return import('pages/MediaManager');
});

const Delivery = lazy(() => {
  return import('pages/Delivery');
});

const PersonalPage = lazy(() => {
  return import('pages/PersonalPortal');
});

const PostProdDispatcher = lazy(() => {
  return import('pages/PostProdDispatcher');
});

const PostProducitonDashboard = lazy(() => {
  return import('pages/PostProduction/PostprodDashboard');
});

const PostProdTechnician = lazy(() => {
  return import('pages/PostProdTechnician');
});

const Shootings = lazy(() => {
  return import('pages/Shootings');
});

const Modals = React.lazy(() => {
  return import('pages/shared/Modals');
});

const SideBars = React.lazy(() => {
  return import('pages/shared/SideBars');
});

const Faq = React.lazy(() => {
  return import('pages/Faq');
});

const Support = React.lazy(() => {
  return import('pages/Support');
});

const PromotionManager = React.lazy(() => {
  return import('pages/PromotionManager');
});

const FeedbackManager = React.lazy(() => {
  return import('pages/FeedbackManager');
});

const Feedback = React.lazy(() => {
  return import('pages/Feedback');
});

const broker_custom_portal = React.lazy(() => {
  return import('pages/PersonalPortal/components/Public/public');
});

const bill_creation_freelancer = React.lazy(() => {
  return import('pages/BillCreationFreelancer/');
});
const CustomShootingOffer = React.lazy(() => {
  return import('pages/CustomShootingOffer/');
});
const BrokerServices = React.lazy(() => {
  return import('pages/Services');
});
const BrokerServiceRequest = React.lazy(() => {
  return import('pages/ServiceRequest');
});
const ClientServices = React.lazy(() => {
  return import('pages/Services');
});
const ClientServiceRequest = React.lazy(() => {
  return import('pages/ServiceRequest');
});
const SubscriptionPlans = React.lazy(() => {
  return import('pages/SubscriptionPlans');
});
// const esoftDashboard = React.lazy(() => {
//   return import('pages/PostProduction/EsoftDashboard');
// });
const jobsDashboard = React.lazy(() => {
  return import('pages/PostProduction/JobsDashboard');
});
const reviewPage = React.lazy(() => {
  return import('pages/PostProduction/Review');
});
const PrivacyPolicy = React.lazy(() => {
  return import('pages/PrivacyPolicy');
});
const RemoteInjector = React.lazy(() => {
  return import('pages/RemoteInjector');
});
const EsoftOrder = React.lazy(() => {
  return import('pages/EsoftOrder');
});
const ProdShootings = React.lazy(() => {
  return import('pages/ProdShootings');
});
const ProdDispatcher = React.lazy(() => {
  return import('pages/ProdDispatcher');
});
const ProdScheduler = React.lazy(() => {
  return import('pages/ProdScheduler');
});
const ImagenDashboard = React.lazy(() => {
  return import('pages/PostProduction/ImagenDashBoard');
});
const source_url = window.location.hostname;

function App() {
  useLanguage();

  const { user } = useAuth();
  const homepage = user ? user.homepage() : 'media-directory';

  const getUserConfirmation = (dialogKey, callback) => {
    const dialogTrigger = window[Symbol.for(dialogKey)];
    if (dialogTrigger) {
      return dialogTrigger(callback);
    }
    return callback(true);
  };

  function handleDomainRedirection() {
    for (let i = 0; i < REDIRECTED_HOSTNAME.length; i++) {
      if (source_url === REDIRECTED_HOSTNAME[i]) {
        return true;
      }
    }
    return false;
  }

  return (
    <ConfirmProvider>
      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        <Suspense fallback={<div />}>
          <HvAlert />
          <Switch>
            {handleDomainRedirection() ? (
              <Switch>
                <Redirect from='/' to={`/${homepage}`} exact />

                <Route path='/login' component={Login} />
                <Route path='/first-time-login' component={FirstTimeLogin} />
                <Route path='/register' component={Register} />
                <Route path='/resetPassword' component={ResetPassword} />
                <Route path='/request-reset-password' component={RequestResetPassword} />
                <Route path='/delivery/:token' component={Delivery} />
                <Route path='/faq' component={Faq} />
                <Route exact path='/support' component={Support} />
                <Route path='/support/:category' component={Support} />
                <Route path='/feedback' component={Feedback} />
                <Route path='/thanks' component={Response} />
                <Route path='/thank-you' component={ThankYou} />
                <Route path='/edit' component={PersonalPage} />
                <Route path='/public-media-page/:token' component={Delivery} />
                <Route path='/services' component={BrokerServices} />
                <Route path='/broker-services' component={BrokerServices} />
                <Route path='/broker-service-request' component={BrokerServiceRequest} />
                <Route path='/service-request' component={BrokerServiceRequest} />
                <Route path='/subscription-plans' component={SubscriptionPlans} />
                <Route path='/privacy-policy' component={PrivacyPolicy} />
                <Route path='/esoft-order/:esoft_order_id' component={EsoftOrder} />
                <AuthRoute path='/profile' component={Profile} />
                <AuthRoute path='/media-directory' component={MediaManager} />
                {/* <AuthRoute path='/post-prod-dispatcher' component={PostProdDispatcher} /> */}
                <AuthRoute path='/post-prod-dashboard' component={PostProducitonDashboard} />
                <AuthRoute path='/post-prod-technician' component={PostProdTechnician} />
                <AuthRoute path='/shootings' component={Shootings} />
                <AuthRoute path='/promotion-manager' component={PromotionManager} />
                <AuthRoute path='/feedback-manager' component={FeedbackManager} />
                <AuthRoute path='/bill-creation-freelancer' component={bill_creation_freelancer} />
                <AuthRoute path='/custom-shooting-offer' component={CustomShootingOffer} />
                {/* {<AuthRoute path='/esoft-dashboard' component={esoftDashboard} />} */}
                <AuthRoute path='/jobs-dashboard' component={jobsDashboard} />
                <AuthRoute path='/review-page/:listing_id' component={reviewPage} />
                <AuthRoute path='/remote-injector' component={RemoteInjector} />
                <AuthRoute path='/prod-shootings' component={ProdShootings} />
                <AuthRoute path='/prod-dispatcher' component={ProdDispatcher} />
                <AuthRoute path='/prod-scheduler' component={ProdScheduler} />
                <AuthRoute path='/imagen-dashboard' component={ImagenDashboard} />
              </Switch>
            ) : (
              <Switch>
                <Redirect from='/' to={'/\u0020'} exact />
                <Route path={'/\u0020'} component={broker_custom_portal} />
                <Route path='/client-services' component={ClientServices} />
                <Route path='/client-service-request/:broker_id' component={ClientServiceRequest} />
              </Switch>
            )}
          </Switch>

          <Modals />
          <SideBars />
        </Suspense>
      </BrowserRouter>
    </ConfirmProvider>
  );
}

export default App;
